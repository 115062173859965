.site-nav {

    background-color: @mainColor;
    color: white;

    &__wrapper {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 0 5px 5px;

        position: relative;

        @media screen and (max-width: @lg) {
            flex-wrap: wrap;
            justify-content: center;
        }

    }


    a {
        color: white;
    }

    i {
        color: @secondColor;
    }

    &__menu.menu {
        padding: 0;

        @media screen and (max-width: @lg) {
            order: 1;
            padding: 13px 0 6px 0;
        }

        @media screen and (max-width: @md) {
            width: 100%;
        }

        .innermenu {
            height: auto;
            margin-right: 50px;
            line-height: 1;

            @media screen and (max-width: @lg) {
                margin-right: 0;
                border-top: 0 !important;
                border-bottom: 0 !important;
                min-height: unset !important;
                width: auto !important;
            }

            .menu-list {
                border-bottom: 0;
                height: auto;
                text-transform: uppercase;

                a {
                    &:hover {
                        text-decoration: none;
                        color: white !important;
                    }
                }

                >li {

                    &.current {
                        >h3 {
                            >a {
                                color: @secondColor;
                            }
                        }
                    }

                    >h3 {
                        font-size: 17px;
                        margin: 0;

                        @media screen and (max-width: @xxl) {
                            font-size: 16px;
                        }

                        @media screen and (max-width: @xl) {
                            font-size: 14px;
                        }

                        >a {
                            padding: 5px 10px;
                            margin: 0 5px;
                            color: white;

                            position: relative;

                            &::before {
                                content: '';
                                position: absolute;
                                bottom: 0%;
                                left: 0px;
                                width: 100%;
                                height: 1px;
                                background: @secondColor;
                                display: block;
                                -webkit-transform-origin: right top;
                                -ms-transform-origin: right top;
                                transform-origin: right top;
                                -webkit-transform: scale(0, 1);
                                -ms-transform: scale(0, 1);
                                transform: scale(0, 1);
                                -webkit-transition: transform 0.3s cubic-bezier(1, 0, 0, 1);
                                transition: transform 0.3s cubic-bezier(1, 0, 0, 1)
                            }

                            &:hover {
                                &::before {
                                    -webkit-transform-origin: left top;
                                    -ms-transform-origin: left top;
                                    transform-origin: left top;
                                    -webkit-transform: scale(1, 1);
                                    -ms-transform: scale(1, 1);
                                    transform: scale(1, 1)
                                }
                            }

                            @media screen and (max-width: @xxl) {
                                padding: 5px 7px;
                                margin: 0 2px;
                            }

                            @media screen and (max-width: @xl) {
                                padding: 5px;
                            }
                        }
                    }

                    >.submenu {
                        ul {
                            background-color: @secondColor;
                            border: 1px solid @secondColor;
                            top: 50px;

                            &::before {
                                background-color: @secondColor;
                                border-top: 1px solid @secondColor;
                                border-left: 1px solid @secondColor;
                            }

                            @media screen and (max-width: @xxl) {
                                top: 49px;
                            }

                            @media screen and (max-width: @xl) {
                                top: 48px;
                                width: 30%;
                            }

                            li {
                                a {
                                    &:hover {
                                        opacity: .6;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

    }

    &__search.search {
        width: 100%;
        max-width: 385px;

        @media screen and (max-width: @xxl) {
            max-width: 295px;
        }

        @media screen and (max-width: @xl) {
            max-width: 250px;
        }

        .search {
            margin: 0;
            flex-grow: 1;

            .search__input-area {
                height: 60px;
                border: 1px solid @secondColor;
                border-radius: 5px;
                background-color: @mainColor;
                padding: 0 10px;

                @media screen and (max-width: @lg) {
                    height: 45px;
                }

                .search__input[type="search"] {
                    background-color: @mainColor;
                    min-height: 50px;
                    color: white;
                    font-size: 14px;
                    font-weight: bold;
                    text-transform: uppercase;

                    &::placeholder {
                        color: white;
                    }

                    @media screen and (max-width: @xl) {
                        font-size: 13px;
                    }

                    @media screen and (max-width: @lg) {
                        min-height: 40px;
                    }
                }

                .search__btn-search {
                    width: 50px;
                    height: 50px;
                    background-color: @mainColor;
                    border: 1px solid @mainColor;
                    font-size: 26px;
                    font-weight: bold;
                    border-radius: 8px;
                    padding: 8px;

                    transition: color 0.3s ease-in-out, background 0.3s ease-in-out;

                    @media screen and (max-width: @lg) {
                        width: 40px;
                        height: 40px;
                    }

                    &:hover {
                        >i {
                            color: white;
                        }
                    }
                }
            }

            .search__content {
                color: black;

                a {
                    color: black;
                }
            }
        }
    }
}