.articlecomments {
    margin: 1rem 0;

    >.boxhead {
        border: 0;

        >h3 {
            font-size: 24px;
        }
    }

    >.innerbox {
        .articlecomment {
            border: 0;
            // background-color: @bgColor2;
            margin: 1rem 0;
            padding: 1rem;

            &:nth-child(even) {
                //  background-color: @bgColor2;
            }

            .reviewer {
                color: @mainColor;
                font-size: 16px;
                font-weight: bold;
                margin: 0;
            }

            .description {
                font-size: 14px;
                line-height: 1.5;
            }
        }

        #commentform {
            form.comment {
                fieldset {
                    >label {
                        font-size: 14px;
                        line-height: 1.2;
                        color: black;
                    }

                    #commentuser,
                    #comment {
                        border-color: rgba(0, 0, 0, .2);
                        border-radius: 3px;

                        &:focus {
                            border-color: @mainColor;
                        }
                    }

                    #comment {
                        min-height: 215px;
                        margin: 0 0 2rem 0;
                    }

                    .btn {
                        background-color: @mainColor;
                        border: 1px solid @mainColor;
                        border-radius: 3px;
                        color: white;
                        text-transform: uppercase;
                        font-weight: bold;
                        font-size: 14px;

                        float: right;
                        width: 100%;
                        max-width: 240px;
                        padding: 15px 5px;

                        &:hover {
                            background-color: white;
                            color: @mainColor;
                        }
                    }
                }
            }
        }
    }
}