.product {
    display: block;
    border: 1px solid @greyColor;

    position: relative;

    &__container {
        padding: 20px;
        margin: 0 auto;
    }

    &__link-wrap {
        &:hover {
            .product__details {
                opacity: 1;
            }
        }
    }

    &__image {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 5px 0 15px 0;

        position: relative;

        .product__thumbnail {
            -o-object-fit: contain;
            object-fit: contain;
            object-position: center;
            height: 170px;
        }

        .product__details {
            width: 100%;
            height: 100%;

            display: flex;
            justify-content: center;
            align-items: center;

            font-size: 22px;
            font-weight: 900;
            text-transform: uppercase;
            text-align: center;

            background: rgba(255, 255, 255, .7);

            position: absolute;
            top: 0;
            left: 0;

            opacity: 0;

            transition: opacity .3s ease-in-out;

            >span {
                padding: 5px;
                position: relative;

                &::after {
                    content: '';
                    display: block;
                    width: 100%;
                    max-width: 118px;
                    height: 3px;
                    background: @secondColor;
                    bottom: 0;
                    left: 0;
                    margin: 3px auto;
                }
            }
        }
    }

    &__header {
        padding: 5px 0;

        .product__title {
            font-size: 16px;
            line-height: 1.375;
            text-align: center;
            margin: 0;
            min-height: 60px;
            max-height: 60px;
            overflow: hidden;
        }
    }

    &__price {
        margin: 0 0 20px 0;

        .price__gross {
            text-align: center;
        }

        .price__current {
            font-size: 16px !important;
            margin: 0 5px;

            &--sale {
                color: @redColor;
            }
        }

        .price__reduced {
            font-size: 14px !important;
        }

    }

    &__buttons {
        .addtobasket {
            padding: 15px;
            width: 100%;

            &:hover {
                color: white;

                &::after {
                    background: @mainColor;
                }
            }
        }
    }

    &__tags {
        .tags {
            display: flex;
            flex-direction: column;

            position: absolute;
            top: 10px;
            right: 10px;
            left: auto;

            .tag {
                font-size: 12px;
                color: white;
                font-weight: 900;
                background-color: @mainColor;
                padding: 5px 10px;

                &--sale {
                    background-color: @redColor;
                }
            }

            &--big {
                justify-content: flex-start;
                align-items: flex-start;

                .tag {
                    font-size: 14px;
                    text-transform: capitalize;
                    padding: 7px 14px;
                }
            }
        }
    }

    &.oneperrow {
        .tags {
            left: 10px !important;
            align-items: flex-start;
        }
    }
}