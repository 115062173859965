.leftcol {
    //@media screen and (max-width: 767px) {
    //    display: block !important;
    //    padding: 1rem !important;
    //}

    .box {

        @media screen and (max-width: 480px) {
            display: block !important;

            &#box_filter {
                margin-bottom: 0;

                .boxhead {
                    display: none;
                }

                .innerbox {
                    .group-filter {
                        display: none;

                        &#filter_price {
                            display: block;
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }

        .boxhead {
            font-size: 1.4rem;
            border-bottom: 0;
        }

        .innerbox {

            ul.standard {
                >li {
                    font-size: 15px !important;
                    margin-bottom: 5px;

                    &.current,
                    &.current_parent {
                        font-weight: bold;

                        >a {
                            color: @secondColor  !important;
                        }
                    }

                    ul {
                        margin-left: 10px !important;

                        >li {
                            list-style-type: none !important;

                            &.current,
                            &.current_parent {
                                font-weight: bold;

                                >a {
                                    color: @secondColor  !important;
                                }
                            }

                            ul {
                                >li {

                                    &.current,
                                    &.current_parent {
                                        font-weight: bold;

                                        >a {
                                            color: @secondColor  !important;
                                        }
                                    }
                                }
                            }
                        }

                    }
                }
            }

            .group {
                >h5 {
                    font-size: 17px;
                    font-weight: bold;
                    padding-bottom: 10px;
                }
            }

            .group-filter {
                width: 100%;
                padding: 0 0 10px;
                border-bottom: 1px solid @border;

                &:last-of-type {
                    border-color: transparent;
                }

                ul {
                    li {
                        line-height: 1 !important;
                        padding: 3px 0;
                        margin: 2px 0;

                        a {
                            font-size: 12px;
                            line-height: 1.2;
                            color: black;

                        }

                        &.selected {
                            background-color: @greyColor  !important;

                            &.fa {
                                &::before {
                                    content: '\e939';
                                    font-family: Frino;
                                    color: @secondColor;
                                }
                            }

                            a {
                                position: relative;

                                &::before {
                                    content: '';
                                    display: block;
                                    width: 8.5px;
                                    height: 8.5px;
                                    background: @secondColor;
                                    position: absolute;
                                    top: 3px;
                                    left: 13.5px;
                                    line-height: 1;
                                }

                                img {
                                    background: transparent !important;
                                    position: relative;
                                    width: 15px !important;
                                    height: 15px !important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}