.newsletter {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 240px;
    max-width: 360px;
    margin: 0 10px 10px 10px;

    &__intro {
        font-size: 16px;
        font-weight: bold;
        line-height: 1.375;
        text-transform: uppercase;
    }

    &__form {
        margin: 15px 0;

        fieldset {
            background-color: white;
        }

        input[type="text"] {
            width: calc(100% - 105px);
            height: 50px;
            padding: 10px 15px;
            margin: 0;
            border: 0;
            color: black;
            text-transform: uppercase;
            font-family: "Lato";
            font-size: 11px;
            font-weight: 900;

            &::placeholder {
                color: black;
            }

            &:focus {
                border: 0;
                box-shadow: none;

                &::placeholder {
                    color: @secondColor;
                }
            }
        }

        .button {
            font-size: 14px;
            border-left: 1px solid @secondColor;
        }

        .newsletter-rodo {
            display: inline-flex;
            align-items: center;

            margin-top: 10px;

            font-size: 12px;
            font-weight: 300;
            line-height: 1.333;

            .checkbox-rodo {
                position: relative;
                margin-bottom: 0;
                border: 1px solid white;
                background: transparent;
                box-shadow: none;
                width: 20px;
                height: 20px;
                min-height: 20px;
                max-height: 20px;
                padding: 9px;
                margin: 0 10px 0 0;
                -webkit-appearance: none;

                &::before {
                    content: '';
                    background: @secondColor;
                    width: 14px;
                    position: absolute;
                    top: 2px;
                    left: 2px;
                    height: 14px;
                    opacity: 0;
                    transition: opacity 0.2s ease-in-out;
                }

                &:checked {
                    &::before {
                        opacity: 1;
                    }
                }
            }

            a {
                color: black;
                font-weight: bold;
                text-decoration: underline;
            }

            @media screen and (max-width: 480px) {
                text-align: center;
            }
        }
    }

    &__social {
        display: inline-flex;
        align-items: center;

        .newsletter__social-links {
            margin-left: 5px;

            a {
                color: @secondColor;
                font-size: 20px;
                margin: 0 5px;
            }
        }

    }


}