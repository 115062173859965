.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 150px;

    &__item {
        width: 60px;
        height: 60px;
        border-radius: 100%;
        border: 5px solid @secondColor;
        border-top-color: rgba(0, 0, 0, 0.3);
        box-sizing: border-box;
        margin-top: -50px;
        animation: loading 1.2s linear infinite;
        -webkit-animation: loading 1.2s linear infinite;
    }

}

@keyframes loading {
    0% {
        transform: rotate(0deg)
    }

    100% {
        transform: rotate(360deg)
    }
}

@-webkit-keyframes loading {
    0% {
        -webkit-transform: rotate(0deg)
    }

    100% {
        -webkit-transform: rotate(360deg)
    }
}