.product-day {
    display: block;
    background-color: @greyColor;
    min-height: 280px;
    margin-top: -2px;

    overflow: hidden;

    &--disabled {
        pointer-events: none;
        -webkit-filter: grayscale(100%);
        filter: grayscale(100%);
    }

    &__wrapper {
        display: flex;
        align-items: center;
        justify-content: center;

        @media screen and (max-width: @xxl) {
            flex-wrap: wrap;
        }
    }

    &__image {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: white;
        flex-basis: calc((545*100%)/1920);
        flex-grow: 1;
        min-width: 280px;
        height: 280px;
        border: 2px solid @secondColor;

        .product-day__image-wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
        }

        .product-day__thumbnail {
            width: auto;
            height: 240px;
            object-fit: contain;
            object-position: center;
        }
    }

    &__header {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: @secondColor;
        flex-basis: calc((545*100%)/1920);
        flex-grow: 1;
        min-width: 280px;
        min-height: 280px;
        padding: 0 15px !important;

        position: relative;

        &::before {
            content: '';
            width: 0;
            height: 0;
            border-top: 50px solid transparent;
            border-bottom: 50px solid transparent;

            border-right: 50px solid @secondColor;

            position: absolute;
            left: -50px;
            top: calc(50% - 50px);
        }

        .product-day__header-intro {
            font-size: 46px;
            font-weight: 900;
            line-height: 1.25;
            color: white;
            margin: 0;
        }

        .product-day__title {
            font-size: 29px;
            font-weight: 300;
            line-height: 2;
            color: white;
            margin: 0;
        }
    }

    &__price-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;
        flex-basis: calc((830*100%)/1920);
        flex-grow: 1;
        min-width: 400px;
        min-height: 280px;
        padding: 0 15px;

        .price {
            flex-basis: calc(100%/2);
            flex-grow: 1;
            min-width: 250px;
            padding: 10px;

            &__save-slogan {
                font-size: 36px;
                font-weight: 900;
                line-height: 1.2;
                text-align: center;
                color: @redColor;

                margin: 0;
            }

            &__gross {
                font-size: 20px;
                font-weight: 300;
                text-align: center;
                margin: 5px 0;
                line-height: 1;

                .color {
                    color: @redColor;
                    font-size: 36px;
                }
            }
        }

        .tobasket {
            flex-basis: calc(100%/2);
            flex-grow: 1;
            min-width: 250px;
            padding: 10px;


            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            &__timer {
                .timer {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    text-align: center;

                    &__countdown {
                        display: inline-flex;
                        align-items: center;
                        justify-content: center;
                    }

                    &__countdown-item {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        text-align: center;

                        width: 74px;
                        height: 80px;

                        border: 2px solid @secondColor;
                        border-radius: 5px;
                        margin: 10px 5px;
                        padding: 5px;

                        font-size: 14px;
                        font-weight: 300;

                        >span {
                            font-size: 36px;
                            font-weight: 900;
                            line-height: 1;
                            margin-bottom: 3px;
                        }
                    }
                }
            }

            &__button {
                >p {
                    font-size: 24px;
                    font-weight: 900;
                }
            }
        }

    }
}