.article-tags {
    width: calc(100% - 20px);
    max-width: 1260px;
    margin: 2rem auto;
    padding: 0 10px;

    &__header {
        .article-tags__title {
            font-size: 24px;
            text-transform: uppercase;
            margin: 0;
        }
    }

    &__tags {
        margin: .5rem 0 1.5rem;

        >li {
            padding: 0 1px !important;
            margin: 5px 10px 5px 0;

            >a {
                font-size: 12px !important;
                font-weight: bold;
                color: white;
                text-transform: uppercase;
                border: 1px solid @border;
                padding: 7px 15px;
                background: @mainColor;
            }
        }
    }
}