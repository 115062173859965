.slider {
    &__brands {
        img {
            width: 165px;
            height: 115px;
            object-fit: contain;
            object-position: center;

        }

        .owl-item {
            border: 1px solid transparent;
            transition: border-color .3s ease-in-out;

            &:hover {
                border-color: @greyColor;
            }
        }

        .owl-prev,
        .owl-next {
            top: calc(50% - 18px);
            font-size: 35px !important;
        }

        .owl-prev {
            left: -17px;
        }

        .owl-next {
            right: -17px;
        }
    }
}