.rwd {
    .menu {
        .innermenu {
            border: 0;

            .menu-mobile {
                @media screen and (max-width: @md) {
                    display: inline-flex !important;
                }

                width: 100%;

                justify-content: space-between;
                align-items: center;

                &__items {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                &__item {
                    .menu-mobile__link {
                        color: black;
                        margin: 0 15px;
                        position: relative;

                        @media screen and (max-width: @sm) {
                            font-size: 27px;
                        }

                        >i {
                            &::before {
                                font-weight: bold;
                            }
                        }

                        &--hamburger {
                            margin: 0;

                            font-size: 2em;
                            padding: 5px 20px;
                            background-color: @secondColor;

                            >i {
                                color: white;
                            }

                            @media screen and (max-width: @sm) {
                                font-size: 14px;
                            }

                            &::before {
                                display: none;
                            }
                        }

                        .menu-mobile__basket-count {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            position: absolute;
                            top: 0px;
                            left: 16px;
                            font-size: 11px;
                            font-weight: bold;
                            color: @mainColor;
                            background-color: white;
                            border-radius: 50%;
                            width: 22px;
                            height: 22px;
                        }
                    }
                }
            }
        }

        //Expanded menu
        #rwd-menu {
            width: 100%;
            background-color: white;
            border-bottom: 0;
            margin-top: 0;

            li {
                border-bottom: 0;
                color: black;

                a {
                    color: black;
                }

                ul {
                    background-color: white;

                    li {
                        &:first-child {
                            border-top: 0;
                        }
                    }
                }
            }
        }
    }
}