#filter_price {
    #slider-range {
        &.ui-widget.ui-widget-content {
            border: 1px solid @mainColor;
        }

        &.ui-slider {
            width: 80%%;
            height: 1px;
            margin: 10px 5% 15px;

            .ui-slider-range {
                background: @mainColor;
            }

            .ui-slider-handle {
                background: @secondColor;
                border: 1px solid @secondColor;
                border-radius: 0;
                height: 15px;
                width: 15px;
                margin-top: -4px;
            }
        }
    }

    .filter_price_preview {
        display: flex;
        justify-content: space-between;
        width: 100%;
        max-width: 145px;
        font-size: 13px;
        padding: 0 0 0 10px;
    }

    .priceinput {
        padding-top: 0 !important;
        display: flex;
        justify-content: flex-end;
        flex-direction: column;

        .bottombuttons {
            text-align: right;
        }
    }
}

.shop_product_promo {
    .priceinput {
        display: none !important;
    }
}