.products--grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: 0 0 1rem 1rem;
    width: 100%;

    @media screen and (max-width: @xl) {
        justify-content: space-around;
        margin: 2.2rem 0 1rem;
    }

    @media screen and (max-width: @sm) {
        justify-content: center;
    }

    .product {
        flex-basis: calc((100% / 3) - 60px);
        flex-grow: 1;

        width: 100%;
        max-width: 275px;
        min-width: 220px;

        margin: 15px 30px;

        border: 1px solid @greyColor  !important;
        padding: 0 !important;
        height: auto !important;

        // &:nth-child(2n+1) {
        //     margin: 15px 0 15px 25px;
        // }

        // &:nth-child(3n+1) {
        //     margin: 15px 25px 15px 0;
        // }
    }
}

.rwd.wrap .main {
    &.layout__wrapper {
        @media screen and (max-width: 767px) {
            margin: 0 auto !important;
        }
    }
}