.button {
    display: inline-block;

    font-size: 12px;
    font-weight: 900;
    color: white;
    text-transform: uppercase;
    text-align: center;

    padding: 12px 25px;
    margin: 0.5em 0;
    position: relative;

    overflow: hidden;

    opacity: 1;


    z-index: 0;

    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;

    &::after {
        -webkit-transition: all 0.3s;
        -moz-transition: all 0.3s;
        -o-transition: all 0.3s;
        transition: all 0.3s;
    }


    &::before,
    &::after {
        background: @secondColor;
        content: '';
        position: absolute;
        z-index: -1;
    }

    &::after {
        content: '';
        height: 100%;
        left: -55%;
        top: 0;
        transform: skew(50deg);
        transition-duration: 0.6s;
        transform-origin: top left;
        width: 0;
    }

    &:hover {
        color: white;
        opacity: 1;

        &::after {
            height: 100%;
            width: 155%;
        }

    }

    &--small {
        padding: 8px 15px;

        &::after {
            left: -45%;
        }

        &:hover {
            &::after {
                width: 150%;
            }
        }
    }

    &--mid {}

    &--big {
        font-size: 14px;
        padding: 15px 75px;
    }

    &--white {
        background-color: white;
        color: @secondColor;
    }

    &--dark {
        background-color: @mainColor;
        color: white;
    }

    &--gold {
        background-color: @secondColor;

        &:hover {
            color: @secondColor;

            &::before,
            &::after {
                background: white;

            }
        }
    }

    &--gold-hover {
        &:hover {
            color: white;

            &::before,
            &::after {
                background: @mainColor;

            }
        }
    }

    &--icon {}
}