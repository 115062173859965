html,
body {
    background-color: white;
    font-family: 'Lato';
    font-style: normal;
}

*,
::after,
::before {
    box-sizing: border-box;
}

a {
    font-family: Lato;
    color: black;
    transition: opacity 0.3s ease-in-out;
    outline: 0;
    cursor: pointer;

    &:hover {
        opacity: .8;
    }
}

button {
    font-family: Lato;
}

img {
    max-width: 100%;
    //width: auto;
    //height: auto;  
}

header {
    border-top: 0;
    padding: 0;
    background: none;
}

.rwd header {
    @media screen and (max-width: @sm) {
        padding: 0;
    }
}

footer {
    margin: 0;
    padding: 0;
}

.d-block {
    display: block;
}


.module {
    margin-bottom: 30px;

    @media screen and (max-width: @sm) {
        margin-bottom: 20px;
    }

    &--about,
    &--contact {
        margin-bottom: 0;
    }

    &--related {
        order: 1;
    }
}


.box {
    .boxhead {
        border: 0;
    }
}


#shoper-foot {
    display: none;
}