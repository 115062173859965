.site-footer {

    &__links {
        .links {
            display: flex;
            //flex-wrap: wrap;
            align-items: flex-start;

            width: 100%;
            margin: 0 auto;

            @media screen and (max-width: @sm) {
                flex-wrap: wrap;
            }

            &__group {
                font-size: 14px; 

                display: flex;
                justify-content: center;
                width: 100%;
                min-width: 240px;
                max-width: 450px;
                margin: 0 10px 10px 10px;
                @media screen and (max-width: @xxxl) {
                    justify-content: flex-start;
                    min-width: 200px;
                }
                @media screen and (max-width: @sm) {
                    margin-top: 20px;
                }
                .links__header {
                    font-size: 18px;
                    font-weight: 900;
                    color: @secondColor;
                    text-transform: uppercase;

                    margin-bottom: 25px;
                }

                .link {
                    &__href {
                        color: white;
                        line-height: 1.8;
                    }
                }
            }
        }
    }
}