.footer-contact {
    display: flex;
    justify-content: center;
    min-width: 240px;
    max-width: 360px;
    margin: 0 10px 10px 10px;

    @media screen and (max-width: @xxxl) {
        justify-content: flex-start;
        min-width: 200px;
    }
    
    &__phones {
        margin-bottom: 10px;
    }

    &__email {
        margin-bottom: 20px;
    }

    &__phone-number, &__email-address, &__hours-week {
        font-size: 16px;
        font-weight: 900;
        line-height: 1.375;
    }

    &__hours-week {
        font-weight: normal;
        strong {
            padding-left: 5px;
        }
    }
}