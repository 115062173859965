#box_productfull {
    .productfull {
        &__gallery {
            &.productimg {
                margin-left: 0;

                .mainimg {
                    width: 100%;
                    height: 545px;
                    display: block;
                    justify-content: center;
                    align-items: center;
                    background: #fff;
                    border: 1px solid transparent;
                    border-radius: 5px;
                    position: relative;
                    overflow: visible;

                    @media screen and (max-width: @sm) {
                        height: 350px;
                    }

                    .img-wrapper {
                        width: 100%;
                        height: 100%;

                        display: flex;
                        justify-content: center;
                        align-items: center;

                        img {
                            max-height: 545px;
                        }
                    }
                }

                .gallery-navigation {
                    position: absolute;
                    right: 0;
                    top: 45%;
                    line-height: 1;

                    width: 100%;
                    display: flex;
                    justify-content: space-between;

                    overflow: hidden;

                    .gallery-left,
                    .gallery-right {

                        display: inline-block;
                        vertical-align: middle;

                        width: 60px;
                        height: 60px;

                        color: @secondColor;
                        font-size: 49px;
                        line-height: 1;
                        text-align: center;
                        margin: 0;

                        transition: opacity 0.3s ease-in-out;

                        &:hover {
                            opacity: 0.6;
                        }
                    }

                    .gallery-left {
                        margin-left: -30px;

                        >i {
                            margin-right: -20px;
                        }
                    }

                    .gallery-right {
                        margin-right: -30px;

                        >i {
                            margin-left: -20px;
                        }
                    }
                }

                .smallgallery {
                    margin: 0 0 0 -5px;

                    ul {
                        li {
                            border: 0;
                            width: 100px;
                            margin: 0 5px;
                            position: relative;

                            a {
                                padding: 0;
                                width: 100%;
                                max-width: 100px;
                                height: 100px;
                                border: 1px solid @border;

                                display: flex;
                                justify-content: center;
                                align-items: center;

                                background: white;

                                transition: border-color .3s ease-in-out;

                                &:hover {
                                    border-color: @secondColor;
                                }


                                >img {
                                    max-width: 90px;
                                    max-height: 90px;
                                }
                            }
                        }

                        &.with-nav {
                            margin: 0;
                        }
                    }

                    .smallgallery-left,
                    .smallgallery-right {
                        width: 20px;
                        height: 20px;
                        color: @secondColor;
                        font-size: 14px;
                        line-height: 22px;
                        text-align: center;
                        border: 0;
                        border-radius: 5px;
                        transition: opacity 0.3s ease-in-out;

                        &:hover {
                            opacity: 0.6;
                        }

                    }

                    .smallgallery-left {
                        border-right: none;
                    }

                    .smallgallery-right {
                        border-left: none;
                    }

                }
            }
        }
    }
}

.shop-gallery .shop-gallery-preview {
    padding: .5em 3em;
}