.module--slider {
    margin: 0;

    .pageslider {
        margin: 0;

        .slides--banners {

            .slider-text {
                width: 100%;
                max-width: 535px;
                margin: 0 5%;

                @media screen and (max-width: @xxxl) {
                    max-width: 450px;
                }

                p {
                    font-size: 65px;
                    line-height: 1.075;

                    @media screen and (max-width: @xxxl) {
                        font-size: 50px;
                    }
                }

                a {
                    display: inline-block;
                    font-size: 24px;
                    font-weight: 900;
                    color: white;
                    text-transform: uppercase;
                    text-align: center;

                    padding: 20px 50px;

                    background-color: @secondColor;

                    @media screen and (max-width: @xxxl) {
                        font-size: 22px;
                        padding: 15px 40px;
                    }
                }
            }

        }

        .slider-nav {
            display: flex;
            justify-content: center;
            align-items: center;

            bottom: 0px;
            right: 0;
            font-size: 16px;
            width: auto;
            min-width: 115px;
            height: 80px;
            padding: 0 20px;
            background-color: white;

            &.bullets {
                >li {
                    background-color: @secondColor;
                    margin: 0 8px;
                    position: relative;

                    &.active {
                        &::before {
                            content: '';
                            width: 25px;
                            height: 25px;
                            border-radius: 50%;
                            border: 1px solid @secondColor;
                            position: absolute;
                            top: -5px;
                            left: -5px;
                        }
                    }
                }
            }
        }

        .ps-arrow-left,
        .ps-arrow-right {
            color: @secondColor;
            top: unset;
            bottom: 50%;
            width: auto;
            height: auto;
            visibility: visible;
            background: none !important;
            transition: .2s ease-in-out;

            &:hover {
                opacity: .6;
            }

            &::before {
                font-family: Frino;
                font-size: 60px;
                // font-weight: bold;
            }
        }

        .ps-arrow-left {
            left: 0 !important;

            &::before {
                content: '\e998';
            }
        }

        .ps-arrow-right {
            right: 0 !important;

            &::before {
                content: '\e997';
            }
        }
    }
}