.company {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 300px;
    margin: 0 10px 10px 10px;
    &__logo {
        width: 100%;
        max-width: 175px;
        margin: 0 0 20px;
    }

    &__description {
        font-size: 12px;
        font-weight: 300;
        line-height: 1.5;
        margin: 0 0 20px;
        padding-right: 15px;
    }

    &__address {
        font-style: normal;
    }

    &__address-content {
        font-size: 16px;
        line-height: 1.375;
        margin: 0 0 10px;
    }
}