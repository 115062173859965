.promobanners {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    &__banner {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        width: 100%;
        height: 280px;
        min-width: 300px;
        flex-basis: calc(100% / 2);
        flex-grow: 1;

        background-repeat: no-repeat!important;
        background-size: cover!important;
    }

    &__header {
        display: block;
        margin: 0 10% 0 10px;
    }

    &__title {
        font-size: 48px;
        font-weight: 300;
        line-height: 1;

        margin: 0 0 20px 0;
        
        > strong {
            display: block;
        }

        &--white {
            color: white;
        }
    }

    &__button {
        color: black;
    }
}