#box_productfull {
    &.productfull {
        width: 100%;
        margin: 0 auto;

        .innerbox {
            max-width: 1280px;
            margin: 0 auto;
        }

        .maininfo {
            background-color: white;
            margin-bottom: 10px;

            &__wrapper {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                align-items: flex-start;
            }

            &__column {
                flex-basis: calc((100% / 2) + 6%);
                flex-grow: 1;
                padding: 30px;

                @media screen and (max-width: @sm) {
                    flex-basis: 100%;
                    padding: 30px 15px;
                }

                &--info {
                    flex-basis: calc((100% / 2) - 6%);
                }
            }


            .boxhead {
                display: flex;
                flex-direction: column;
            }
        }

    }
}