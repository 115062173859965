.tab-container {
    margin: 1rem 0 2rem 0;

    nav {
        margin-bottom: 0;
        max-width: 1280px;
        margin: 0 auto;

        ul {
            border-bottom: 1px solid @border;
            position: relative;

            display: flex;

            @media screen and (max-width: 575px) {
                flex-direction: column;
                padding: 0;
            }

            li {
                //background-color: @greyColor;
                padding: 0 5px 0 0;

                .boxhead {
                    position: relative;
                    border: 0 !important;
                    background: @greyColor;

                    h3 {
                        margin: 0;
                        padding: 20px 30px;
                        font-size: 17px;
                        font-weight: bold;
                        text-transform: uppercase;
                        line-height: 1.2;
                        color: black;
                        position: relative;

                        @media screen and (max-width: 767px) {
                            // font-size: 22px;
                        }
                    }

                    &.active {
                        background-color: white;
                        border-top: 1px solid @border  !important;
                        border-right: 1px solid @border  !important;
                        border-left: 1px solid @border  !important;

                        // &::after {
                        //     content: '';
                        //     width: 100%;
                        //     height: 5px;
                        //     border-radius: 5px;
                        //     background-color: @mainColor;

                        //     position: absolute;
                        //     left: 0;
                        //     bottom: -4px;

                        //     opacity: 1;
                        // }
                    }
                }
            }
        }
    }

    .product-tabs {
        padding: 3em 1em;
        border-left: 1px solid @border;
        border-right: 1px solid @border;
        border-bottom: 1px solid @border;
        max-width: 1280px;
        margin: 0 auto;

        .product-tab {
            .innerbox {
                padding: .5rem 1rem;

                .resetcss {
                    p {
                        font-size: 18px;
                        line-height: 1.667;

                        @media screen and (max-width: @md) {
                            font-size: 16px;
                            line-height: 1.4;
                        }
                    }

                    img {
                        //border-radius: 25px;
                        max-width: 100%;
                        height: auto;
                    }
                }
            }

            .product-attributes {
                table.table {
                    margin: 1rem 0;

                    td {
                        padding: 10px;

                        &:first-child {
                            // background-color: @bgColor;
                            // border-bottom: 1px solid  @borderColor3;
                        }
                    }
                }
            }
        }
    }
}