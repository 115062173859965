.articles {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 auto;

    &__main,
    &__rest {
        margin: 0 0 20px 0;

        .article {
            display: flex;
            align-items: center;
            margin-bottom: 25px;

            &:last-child {
                margin-bottom: 0;
            }

            &__container {
                display: flex;
                align-items: center;

                @media screen and (max-width: @sm) {
                    flex-direction: column;
                }
            }

            &__thumbnail {
                background-repeat: no-repeat;
                width: 100%;
                min-width: 200px;
                max-width: 500px;
                height: 335px;
                background-size: contain;
                background-position: center center;
                position: relative;

                @media screen and (max-width: @md) {
                    height: 225px;
                }
            }

            &__date {
                display: flex;
                padding: 5px 0;
                font-size: 12px;
                font-weight: bold;
            }

            &__content {
                .article__link-wrap {
                    &:hover {
                        .article__header {
                            .article__title {
                                color: @secondColor;
                            }
                        }
                    }
                }

                .article__header {
                    .article__title {
                        font-size: 24px;
                        line-height: 1.2;
                        transition: color .3s ease-in-out;
                    }
                }


                .article__header {
                    padding: 0;

                    .article__title {
                        font-size: 14px;
                        font-weight: bold;
                        margin: 0 0 5px 0;
                    }
                }

                .article__excerpt {
                    font-size: 14px;
                    line-height: 1.5;
                }

                .article__read-more {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;

                    font-weight: bold;
                    text-align: right;

                    margin-top: 4px;

                    /*&::after {
                        content: '\e912';
                        font-family: Frino;
                        font-weight: bold;
                        color: @mainColor;
                        padding: 2px 0 0 5px;
                    }*/
                }
            }
        }
    }

    //only for main article
    &__main {
        width: 100%;
        flex-basis: 60%;
        max-width: 1120px;
        min-width: 400px;

        @media screen and (max-width: @sm) {
            min-width: 250px;
        }

        .article {
            width: 100%;

            &__container {

                align-items: flex-start;
                position: relative;

                width: 100%;

                @media screen and (max-width: @md) {
                    flex-direction: column;
                    align-items: center;
                }
            }

            &__content {
                width: 100%;
                //max-width: 420px;
                min-width: 300px;
                margin: 0;
                padding: 45px;

                background-color: white;
                z-index: 1;

                position: relative;

                @media screen and (max-width: @md) {
                    margin: 0;
                }

                .article__header {
                    .article__title {
                        font-size: 24px;
                        line-height: 1.2;
                        transition: color .3s ease-in-out;
                    }
                }

                .article__excerpt {
                    font-size: 14px;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    align-items: flex-end;

                    .article__read-more {
                        font-size: 14px;
                        font-weight: 900;
                        color: @secondColor;
                        text-transform: uppercase;
                        margin-top: 7px;
                        border-bottom: 1px solid @secondColor;
                        transition: .3s ease-in-out;

                        &:hover {
                            border-color: @mainColor;
                            color: @mainColor;
                        }

                    }


                }

                /*.article__button {
                    position: absolute;
                    bottom: -100px;
                    left: auto;
                    right: auto;
                    transform: translate(50%, -50%);

                    @media screen and (max-width: @sm) {
                        left: 0;
                    }
                }*/


            }
        }
    }

    //only for rest articles
    &__rest {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        width: 100%;
        flex-basis: 60%;
        min-width: 300px;
        max-width: 515px;
        padding: 35px;
        background-color: @greyColor2;

        >h4 {
            margin: 0 0 15px 0;
            font-size: 22px;
            padding: 0 30px;
            font-weight: bold;
            order: -1;
        }

        .article {
            margin-bottom: 10px;
            flex-basis: unset !important;
        }
    }
}