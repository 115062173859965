.site-footer {
    background-color: @mainColor;
    color: white;
    padding: 30px 0 30px 0;

    a {
        color: white;
    }

    &__wrapper {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        width: calc(100% - 40px);
        max-width: 1600px;
        margin: 0 auto;
        //padding: 0 20px;

        @media screen and (max-width: @xl) {
            flex-wrap: wrap;
            justify-content: flex-start;
        }
        > div {
            margin-top: 20px;
            flex-grow: 1;
        }
    }

    &__heading {
        margin-bottom: 25px;
    }
    
}