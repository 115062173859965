.breadcrumbs {
    padding: 0;
    transition: .3s ease-in-out;
    margin: 20px 0 10px 0;

    .innerbreadcrumbs {
        width: 100%;
        max-width: 1300px;
        padding: 0 10px;

        .breadcrumb-home {
            span {
                display: block;
                font-size: 18px;
                color: @secondColor;
            }

            img {
                display: none;
            }
        }

        .path {
            display: flex;
            align-items: baseline;
            flex-wrap: wrap;
            font-weight: 500;

            li {
                padding: 0;
                display: inline-block;
                font-size: 12px;
                font-weight: bold;
                text-transform: uppercase;

                .raq {
                    color: @secondColor;
                }

                &.bred-1 {
                    order: -1;
                    display: none;

                    .raq {
                        display: none;
                    }
                }

                &.last {
                    color: @secondColor;

                    .raq {
                        color: @secondColor;
                    }
                }

                span {
                    &.raq {
                        font-weight: normal;
                        margin: 0 7px 0;
                    }
                }
            }
        }
    }
}