.user-panels {
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: @md) {
        display: none;
    }

    .user-panel {
        padding: 8px 15px;
        &--basket {
            background-color: @secondColor;
        }

        &__container {
            display: flex;
        }

        &__link-wrap {
            display: flex;
        }

        &__icon {
            margin: 0 8px 0 0;
            font-size: 18px;

            &--white {
                font-size: 20px;
                > i {
                    color: white;
                }
            }
        }

        &__label {
            font-size: 16px;
            font-weight: 900;
            text-transform: uppercase;

            > .basket-count {
                font-weight: normal;
            }
        }

        &__actions {
            display: flex;
            align-items: center;
        
            font-size: 14px;

            .actions__link {
                &:nth-child(1) {
                    padding-right: 5px;
                    border-right: 1px solid @secondColor;
                }
                &:nth-child(2) {
                    padding-left: 5px;
                }
            }
            
            > .basket-value {
                font-weight: 900;
                padding-left: 5px;
            }
            
        }

    }
}