.innermain {
    >.s-row {
        width: 100%;
        margin: 0 auto 3rem 0;
        display: flex;
        justify-content: center;
    }
}

.container {
    max-width: 1920px;
    width: 100% !important;
    margin: 0 auto;

    &::before,
    &::after {
        content: unset;
    }

    &--wide {
        max-width: 100%;
    }
}

.main {
    @media screen and (max-width: @md) {
        margin: 0 1em !important;
    }
}


.layout {

    &__wrapper {
        width: 100%;
        max-width: 1300px;
        margin: 0 auto;
        padding: 0 10px;
    }

    .layout__boxhead {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        padding: 0;
        margin-bottom: 10px;

        position: relative;

        @media screen and (max-width: @md) {
            justify-content: flex-start;
            margin-top: 25px;
        }

        .layout__header {
            padding: 0;
        }

        .layout__title {
            font-size: 24px;
            font-weight: bold;
            text-transform: uppercase;
            letter-spacing: 2px;
            line-height: 1.2;

            margin: 0;
        }

        .search-meta {
            margin-bottom: 10px;
        }
    }

    .layout__content {
        // margin: 0.5rem;

        &.layout__content--bordered {
            //border: 1px solid @textColor;
        }

        &.layout__content--right {
            text-align: right;
        }
    }

    &.layout--columns {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;

        width: 100%;

        @media screen and (max-width: @md) {
            justify-content: center;
        }

        //   @media screen and (max-width: @sm) {
        //       display: block;
        //   }

        &.layout--align-middle {
            align-items: center;
        }

        &.layout--content {
            //margin: 0 -1rem;
        }

        .layout__column {
            position: relative;

            flex-basis: 0;
            flex-grow: 1;

            box-sizing: border-box;
            max-width: 100%;
            width: 100%;
            min-height: 1px;

            margin: 0;

            &--single-product {
                display: flex;
                flex-direction: column;
            }

            &.layout__column--aside {
                width: 100%;
                max-width: 280px;
                min-width: 250px;
                //min-height: 250px;

                padding: 0px 25px 25px 0;

                margin-bottom: 20px;

                @media screen and (max-width: @lg) {
                    max-width: 250px;
                }

                @media screen and (max-width: @sm) {
                    min-height: unset;
                    display: none;
                }

                .box {
                    width: 100%;
                    margin-bottom: 0rem;
                    padding: 0 3px;

                    .boxhead {
                        border: 0;

                        >span {
                            font-size: 30px;
                            margin-top: 15px;
                        }
                    }
                }
            }

            &.layout__column--main {
                padding: 0 1rem;
                margin: 0;

                @media screen and (max-width: @sm) {
                    padding: 0 .5rem;
                }
            }

            &.layout__column--center {
                padding: 0 0 0 1rem;
                margin: 0;

                @media screen and (max-width: @sm) {
                    padding: 0;
                }

                .boxhead {
                    border: 0;

                    >h3 {
                        font-weight: 500;
                    }
                }
            }

            &.layout__column--full {
                width: auto;
                max-width: unset;
                padding: 0;
                margin: 0 -1rem;
            }
        }
    }

    &--single {
        //max-width: 1280px;
        margin: 0 auto;
    }

    &.layout--products {

        @media screen and (max-width: @md) {
            justify-content: center;
        }


        .layout__column {

            &.layout__column--aside {
                @media screen and (max-width: @md) {
                    display: block !important;
                    padding: 0;
                }

                @media screen and (max-width: @sm) {
                    min-height: unset;
                }

                .box {
                    @media screen and (max-width: @md) {
                        display: none;
                    }

                    &#box_filter {
                        @media screen and (max-width: @md) {
                            display: block;
                        }
                    }

                    &#box_menu {
                        @media screen and (max-width: @sm) {
                            display: none;
                        }
                    }
                }
            }


        }
    }

    &.layout--tiles {
        display: flex;
        flex-wrap: wrap;

        align-items: stretch;

        // margin: 0 -0.2rem;

        &.layout--tiles--half {
            margin: 0 -0.5rem;
        }

        .layout__tile {
            position: relative;

            flex-basis: 0;
            flex-grow: 1;

            box-sizing: border-box;
            max-width: 100%;
            width: 100%;
            min-height: 1px;

            padding: 0.2rem;

            &.layout__tile--quarter {
                flex-basis: calc(~'100% / 4');
                flex-grow: 0;
            }

            &.layout__tile--3 {
                flex-basis: calc(~'100% / 3');
                flex-grow: 0;
            }

            &.layout__tile--half {
                flex-basis: calc(~'100% / 2');
                flex-grow: 0;

                padding: 0.5rem;

                @media screen and (max-width: 480px) {
                    flex-basis: 100%;
                }
            }
        }
    }
}