.products-category-name {
    margin-bottom: 15px;
}

.sort-and-view {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    min-height: 55px;
    height: 55px;
    padding: 0 30px;
    background-color: @greyColor3;

    .sort-label {
        font-size: 16px;
        font-weight: bold;
    }

    .prodview {
        top: unset;

        li {
            &.selected {
                a {
                    color: @secondColor;
                }
            }
        }
    }

    .sortlinks {
        padding-right: 15px;
    }

    .sort-by-name,
    .sort-by-price {
        margin-left: 12px;

        a {
            font-size: 16px;
        }

        &.sort--ASC,
        &.sort--DESC {
            a {
                position: relative;
                display: inline-block;
                padding-right: 16px;
                color: @secondColor;

                &::after {
                    content: "\e914";
                    position: absolute;
                    top: 15%;
                    right: 0;
                    color: @secondColor;
                    font-family: 'frino';
                    font-weight: 400;
                    font-style: normal;
                    font-size: 16px;
                    line-height: 1;
                    letter-spacing: normal;
                    text-transform: none;
                    display: inline-block;
                    vertical-align: middle;
                    white-space: nowrap;
                    word-wrap: normal;
                    direction: ltr;
                    -webkit-font-feature-settings: 'liga';
                    font-feature-settings: 'liga';
                    -webkit-font-smoothing: antialiased;
                }
            }
        }
    }
}