.slider {
    &__container {
        padding: 0 16px;
        overflow: hidden;
    }

    &__more {
        text-align: center;
        margin: 30px 0 0 0;
    }

    &:hover {
        .owl-nav {
            opacity: 1;
        }
    }

    .owl-nav {
        display: block !important;
        width: 100%;
        opacity: 0;
        transition: opacity .5s ease-in;
    }

    .owl-prev,
    .owl-next {
        position: absolute;
        top: calc(50% - 35px);

        color: @secondColor  !important;

        font-size: 60px !important;
        line-height: 1;
        text-align: center;
        margin: 0 !important;
        transition: all 0.3s ease-in-out;
        overflow: hidden;

        display: flex !important;
        align-items: center;

        >i {
            transition: color .3s ease-in-out;
        }

        &:hover {
            >i {
                color: @mainColor;
            }
        }
    }


    .owl-prev {
        left: -30px;
        justify-content: flex-end;
    }

    .owl-next {
        right: -30px;
        justify-content: flex-start;
    }
}