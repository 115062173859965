.credits {
    width: 100%;
    margin-top: 20px;
    a {
        color: white;
    }
    &__wrapper {
        width: 100%;
        padding: 0 20px;

        display: flex;
        align-items: center;
        justify-content: space-between;
        @media screen and (max-width: @lg) {
            flex-direction: column;
        }
    }

    .credit {
        margin: 0 10px;
        font-size: 13px;

        &--copyright {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            text-align: center;
        }
        .avswiat {
            font-weight: 900;
            margin: 3px 5px;
        }
        .shoper {
            margin: 0 5px;
        }



    }

}