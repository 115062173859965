.single-article-header {
    margin-bottom: 0;
    margin-top: 10px;
    padding: 25px 0 !important;
    border: 1px solid @border;
    display: flex;
    justify-content: center;
    align-items: center;

    h1 {
        margin: 0;
        font-size: 24px;
        color: #000000;
        text-transform: uppercase;
        flex-basis: calc(100%/3);
        text-align: center;
    }

    @media screen and (max-width: @md) {
        margin-top: 1.5rem;
    }
}

.blog-single__content {
    padding: 0 2rem !important;
}

.single-article {
    width: calc(100% - 20px) !important;
    max-width: 1470px;
    margin: 0 auto 3rem auto !important;
    padding: 0 10px;

    &__info-wrapper {
        position: relative;
        margin: 40px 0;
        min-height: 50px;
        z-index: 0;
    }

    &__image {
        .article-image {
            object-fit: contain;
            object-position: center;
            max-height: 365px;
            width: 100%;

            @media screen and (max-width: @sm) {
                max-height: 250px;
            }
        }
    }

    &__info {
        margin: 10px 0;

        .article-date,
        .article-categories {
            color: white;
            font-size: 14px;
            font-weight: bold;
            border-radius: 40px;
            padding: 5px 25px 5px 15px;
            margin: 0 10px;

            >a {
                color: white;
            }
        }

        .article-date {
            background: @mainColor;
        }

        .article-categories {
            background: black;
        }
    }

    &__header {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;

        text-align: center;

        background-color: white;
        border: 1px solid @border;

        margin: 0 auto;
        padding: 15px;

        width: 100%;
        max-width: 1080px;

        position: relative;
        z-index: 1;

        @media screen and (max-width: @xl) {
            max-width: calc(100% - 30px);
        }

        @media screen and (max-width: @sm) {
            margin: 0 auto;
            max-width: 100%;
        }

        .article-name {
            font-size: 36px;
            text-transform: uppercase;
            line-height: 1;
            margin: 0;
            padding: 5px 0;

            @media screen and (max-width: @sm) {
                font-size: 18px;
            }
        }

        .article-facebook {
            display: block;

            &__link {
                display: inline-flex;
                align-items: center;
                justify-content: center;
                background-color: @greyColor;
                border: 1px solid @border;
                color: black;
                font-size: 12px;
                text-decoration: none;
                text-transform: uppercase;
                padding: 8px 30px;
                margin: 15px 0 0;
            }

            i {
                font-size: 14px;
                padding-right: 3px;
            }
        }
    }

    &__author {
        color: black;
        font-size: 14px;
        text-align: center;
        margin: 5px 0 0 0;
        padding: 15px 0;
        border-bottom: 1px solid @border;

        .article-author {
            font-size: 16px;
            font-weight: bold;
        }
    }

    .article-content {
        width: 100%;
        max-width: 1260px;
        margin: 1rem auto;

        p {
            font-size: 16px;
            line-height: 1.5;
        }

        img {
            height: auto;
        }
    }
}