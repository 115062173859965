#box_productfull {
    .productinfo {
        &__wrapper {
            width: 100%;
            min-width: 345px;
            margin: 0 auto 3rem auto;

            @media screen and (max-width: @sm) {
                min-width: 270px;
            }

            .boxhead {
                &__name {
                    font-size: 48px;
                    font-weight: normal;
                    text-align: left;
                    line-height: 1.2;
                    margin: 10px 0 5px 0;
                }

                &__code {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    flex-wrap: wrap;

                    padding: 0 0 15px;

                    font-size: 14px;
                    text-align: left;
                    line-height: 1.429;

                    .code {
                        margin-right: 15px;

                        >span {
                            font-weight: bold;
                            font-size: 16px;
                        }
                    }

                    .manufacturer {
                        >.brand {
                            font-weight: bold;
                            font-size: 16px;
                        }
                    }
                }

                &__evaluation {
                    .evaluation {
                        display: flex;
                        align-items: center;
                        margin: 0 0 10px 0;

                        .vote-message {
                            margin: 0 5px 5px 0;
                            font-weight: bold;
                            font-size: 14px;
                            border-bottom: 1px solid @secondColor;
                        }

                        .votestars {
                            float: left;
                            margin: 0 0 5px 0;

                            img[class*="star"] {
                                margin: 0 5px 2px 0;
                            }
                        }
                    }
                }

            }

            .price-availability {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                justify-content: space-between;
                margin: 5px 0 10px 0;

                .price {
                    margin-bottom: 15px;
                    display: flex;
                    align-items: baseline;

                    &__name {
                        display: none;
                    }

                    &__main {
                        font-size: 36px;
                        font-weight: 900;
                        color: @secondColor;
                        order: 1;

                        &--sale {
                            color: @redColor;
                        }
                    }

                    &__del {
                        font-size: 14px;
                        margin-right: 7px;
                        // color: @redColor;
                    }
                }

                .otherprice {
                    margin-bottom: 15px;
                }

                .productavailability {
                    display: flex;
                    justify-content: flex-start;
                    align-items: baseline;
                    margin-bottom: 15px;

                    @media screen and (max-width: @sm) {
                        flex-direction: column;
                    }

                    &__box {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        flex-wrap: wrap;
                        flex-basis: 100%;
                        margin-right: 10px;

                        font-size: 16px;

                        @media screen and (max-width: @sm) {
                            margin: 5px 0;
                        }

                        >.first {
                            margin-right: 5px;
                            padding: 4px 0;
                        }

                        >.second {
                            font-size: 18px;
                            font-weight: bold;
                            padding: 4px 0;
                        }
                    }
                }
            }

            .productdelivery {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 20px;
                padding: 20px 0;
                border-top: 1px solid @border;
                border-bottom: 1px solid @border;

                &__box {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    flex-wrap: wrap;
                    // flex-basis: calc(100% / 2);
                    margin-right: 10px;

                    font-size: 16px;

                    @media screen and (max-width: @sm) {
                        margin: 5px 0;
                    }

                    >.first {
                        margin-right: 5px;
                        padding: 4px 0;
                    }

                    >.second {
                        font-size: 18px;
                        font-weight: 900;
                        color: @secondColor;
                        padding: 4px 0;
                    }

                    &::before {
                        font-size: 30px;
                        font-family: Frino;
                        color: @secondColor;
                        padding-right: 5px;
                    }

                    &.delivery {
                        &::before {
                            content: '\e923';
                            font-weight: 900;
                        }
                    }
                }
            }

            .bottomborder {
                .basket {
                    margin-top: 0;

                    .form-basket {
                        margin: 0;

                        .stocks {
                            .stock {
                                &__label {
                                    padding: 0 20px;

                                    >.label {
                                        font-size: 16px;
                                        font-weight: bold;
                                        line-height: 2.146;

                                        >.color {
                                            display: none;
                                        }
                                    }
                                }

                                &__options {
                                    width: 100%;
                                    max-width: 385px;
                                    margin-bottom: 15px;
                                    position: relative;

                                    @media screen and (max-width: @sm) {
                                        max-width: 250px;
                                    }

                                    .option_select {
                                        select {
                                            height: 45px;

                                            background: white;
                                            border: 1px solid @border;
                                            padding: 0 15px;
                                            font-size: 13px;
                                            color: black;

                                            transition: .3s ease-in-out;

                                            &:hover {
                                                color: black;
                                                border-color: @mainColor;
                                            }
                                        }

                                        &::after {
                                            content: '\e910';
                                            font-family: Frino;
                                            font-size: 11px;
                                            font-weight: bold;
                                            color: white;
                                            line-height: 1;
                                            position: absolute;
                                            top: 13px;
                                            right: 20px;
                                            background-color: @mainColor;
                                            border-radius: 50%;

                                            padding: 4px;
                                            pointer-events: none;
                                        }
                                    }

                                    .option_color {
                                        margin: 0;
                                    }
                                }
                            }
                        }

                        .addtobasket-container {
                            display: flex;
                            flex-wrap: wrap;
                            justify-content: space-between;
                            width: 100%;
                            margin: 5px 0 20px;

                            @media screen and (max-width: @sm) {
                                display: flex;
                                flex-direction: column;
                            }

                            .quantity_wrap {
                                width: 100%;
                                flex-basis: 40%;
                                margin: 5px 0;

                                @media screen and (max-width: @sm) {
                                    margin-bottom: 20px;
                                }

                                .number-wrap {
                                    #incdec {
                                        display: flex;
                                        align-items: center;

                                        font-size: 24px;
                                        font-weight: 500;
                                        line-height: 1.25;

                                        #up,
                                        #down {
                                            cursor: pointer;
                                            color: @secondColor;
                                        }

                                        .product-quantity {
                                            margin: 0 10px;
                                            width: 50px !important;
                                            min-width: 50px !important;
                                            height: 50px;
                                            text-align: center;
                                            color: black;

                                            font-size: 16px;
                                            font-weight: bold;

                                            border: 1px solid @border;
                                            background-color: white;

                                            -webkit-appearance: textfield;
                                            -moz-appearance: textfield;
                                            appearance: textfield;
                                        }


                                    }

                                    .unit {
                                        font-size: 16px;
                                        font-weight: bold;
                                        color: black;
                                        margin-left: 10px;
                                    }
                                }
                            }

                            .button_wrap {
                                width: 100%;
                                flex-basis: 60%;
                                flex-grow: 1;
                                margin: 5px 0;
                                min-width: 200px;
                                display: flex;

                                // @media screen and (max-width: @xs) {
                                //     width: 67%;
                                //     margin-left: 10%;
                                //     min-width: unset;
                                // }

                                .addtobasket {
                                    font-family: Lato, sans-serif;
                                    text-transform: uppercase;
                                    font-size: 14px;
                                    max-width: 300px;
                                    height: 50px;
                                    background-color: @secondColor;

                                    &:hover {
                                        color: white;

                                        &::after {
                                            background: @mainColor;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .productbottom {
                    width: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    align-items: center;

                    font-size: 14px;
                    font-weight: bold;

                    line-height: 1.2;

                    &__link {
                        display: flex;
                        align-items: center;
                        margin: 5px 10px;

                        font-size: 14px;

                        &:last-of-type {
                            margin: 5px 0 5px 10px;
                        }

                        &::before {
                            font-family: Frino;
                            font-size: 18px;
                            font-weight: bold;
                            line-height: 1;
                            color: @secondColor;

                            margin-right: 2px;
                        }

                        &--question {
                            &::before {
                                content: '\e92f';
                            }
                        }

                    }

                    .fb_buttons {
                        margin: 5px 10px;
                    }

                }
            }

        }


    }
}