.site-header {
    background-color: @mainColor;
    color: white;

    a {
        color: white;
    }

    i {
        color: @secondColor;
    }

    &__wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
       // flex-wrap: wrap;

        width: 100%;
        min-height: 65px;

        padding: 5px;

        @media screen and (max-width: @xl) {
            flex-wrap: wrap;
            justify-content: center;

            > div {
                margin-bottom: 10px;
            }
        }

    }
}