.logo {
    //width: 100%;
    //max-width: 445px;
    //min-width: 300px;
    display: flex;
    justify-content: center;
    margin: 0 5px 0 90px;

    @media screen and (max-width: @xxl) {
        margin: 0;
    }
    
    &__link-wrap {
        > h1 {
            margin: 0;
            line-height: 1;
        }
    }
}