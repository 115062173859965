.contact {
    @media screen and (max-width: @md) {
        margin-bottom: 0 !important;
    }

    @media screen and (max-width: @sm) {
        display: none;
    }

    &__list {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5px;

        @media screen and (max-width: @sm) {
            flex-wrap: wrap;
        }
    }

    &__item {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 20px;

        @media screen and (max-width: @xxl) {
            margin: 0 10px;
        }

        @media screen and (max-width: @sm) {
            margin: 5px 10px;
        }

        .contact__icon {
            font-size: 21px;
            margin-right: 10px;

            @media screen and (max-width: @xxl) {
                margin-right: 5px;
            }

            &::before {
                font-weight: bold;
            }
        }

        .contact__action {
            font-size: 18px;
            font-weight: 900;

            @media screen and (max-width: @xxl) {
                font-size: 16px;
            }
        }
    }
}