h1,
h2,
h3,
h4,
h5,
h6 {
    color: inherit;
}

b,
strong {
    font-weight: 900;
}

.heading {
    font-size: 18px;
    font-weight: 900;
    color: @secondColor;
    text-transform: uppercase;
    margin-bottom: 5px;

    &--small {
        font-size: 12px;
        font-weight: 300;
    }
}


.module {
    &__header {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 100%;
        padding: 0 0 10px 0;
        margin: 0 0 30px 0;

        position: relative;

        &--product-list {
            justify-content: flex-start;
            margin: 0;
            text-align: left;
        }

        &--basket {
            margin-top: 20px;
        }

        .module__title {
            font-size: 36px;
            padding: 10px 0;
            margin: 0;
            text-align: center;
            border-bottom: 2px solid @secondColor;
            position: relative;

            &.category-name {
                font-size: 48px;
                font-weight: 300;
                text-align: left;
                border-bottom: 0;
            }
        }

        &--related {
            .module__title {
                font-size: 30px;
            }
        }
    }
}