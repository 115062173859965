.paginator-wrapper {
    text-align: right;

    @media screen and (max-width: 767px) {
        margin: 2.2rem 1rem 2rem;
    }

    @media screen and (max-width: 530px) {
        margin: 0 1rem 2rem;
    }

    &--top {
        @media screen and (max-width: 530px) {
            display: none;
        }
    }

    .paginator {
        margin: 0;
        padding: 0;

        li {
            margin: 0 2px;
            height: 35px;
            min-width: 35px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            // line-height: 1;
            border: 1px solid @mainColor;
            transition: .5s ease;

            >a,
            span {
                border: 0;
                padding: 5px;
                color: @mainColor  !important;
                font-size: 14px;
                font-weight: bold;
            }

            &.first,
            &.last {
                border: 1px solid @secondColor;

                min-width: 60px;


                >a,
                span {
                    color: @secondColor  !important;
                    padding: 10px 20px;
                }
            }


            &:hover,
            &.selected {
                border-color: @secondColor;
                background-color: @secondColor;

                >a,
                span {
                    color: white !important;
                    opacity: 1;
                }
            }
        }
    }
}