.basket-steps {
    ol {
        li {
            &::before {
                background-color: #fff;
                color: @secondColor;
                border: 1px solid @secondColor;
                padding: 25px 29px;
            }

            &::after {
                content: '';
                width: 50%;
                height: 1px;
                background-color: @secondColor;
                display: block;
                position: absolute;
                top: 35%;
                left: 75%;
                z-index: 0;
            }

            &:last-of-type {
                &::after {
                    display: none;
                }
            }

            &.active-step {
                &::before {
                    background-color: @secondColor;
                    color: #fff;
                }
            }

            &.mark-green {
                &::before {
                    color: white;
                }
            }

            span {
                color: #000000;
                font-size: 14px;
                font-weight: bold;
            }
        }
    }
}